import React, { useEffect, useState } from "react";
import { auth, dbFirestore } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { Navbar } from "./navbar";
import { useNavigate } from "react-router-dom";
import "./Login.css";

function Profile() {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Listen for authentication state changes
        auth.onAuthStateChanged(async (user) => {
          if (user) {
            // If user is logged in, fetch their data
            try {
              const docRef = doc(dbFirestore, "Users", user.uid);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                setUserDetails(docSnap.data());
                console.log(docSnap.data());
              } else {
                console.log("No such document!");
              }
            } catch (error) {
              console.error("Error fetching user data:", error);
              setError("Error fetching user data.");
            }
          } else {
            console.log("No user is logged in.");
            navigate("/login"); // Redirect to login if no user is logged in
          }
        });
      } catch (error) {
        console.error("Error with authentication:", error);
        setError("Error with authentication.");
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/login"); // Use navigate for redirection
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
      setError("Error logging out.");
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Display loading state
  }

  if (error) {
    return <p>{error}</p>; // Display error state
  }

  return (
    <div>
      <Navbar />
      {userDetails ? (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={userDetails.photo || "default-image-url"} // Fallback to a default image if none is provided
              width={"40%"}
              style={{ borderRadius: "50%" }}
              alt="User"
            />
          </div>
          <h3>Welcome {userDetails.firstName} 🙏🙏</h3>
          <div>
            <p>Email: {userDetails.email}</p>
            <p>First Name: {userDetails.firstName}</p>
            {/* <p>Last Name: {userDetails.lastName}</p> */}
          </div>
          <button className="btn btn-primary" onClick={handleLogout}>
            Logout
          </button>
        </>
      ) : (
        <p>No user details available.</p> // Display message if no user details are available
      )}
    </div>
  );
}

export default Profile;
